"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var slidetoggle_1 = require("slidetoggle");
var footerToggles = document.querySelectorAll(".footer__menu-fold-button");
footerToggles.forEach(function (footerToggle) {
    return footerToggle.addEventListener("click", function () {
        var currentFooterToggle = footerToggle;
        currentFooterToggle.style.visibility = "hidden";
        (0, slidetoggle_1.show)(currentFooterToggle.previousElementSibling.querySelector(".folded"), {
            miliseconds: 400,
            transitionFunction: "swing",
        });
    });
});
