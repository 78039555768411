/**
 * Opens a specific slide in the slideshow popup
 *
 * @param slideshow The slideshow in which the slide will open
 * @param slideNumber The slideNumber which will open
 */
var openSlide = function (slideshow, slideNumber) {
    if (!slideshow || !slideNumber)
        return;
    slideshow
        .querySelectorAll(".slide")
        .forEach(function (el) { return el.classList.remove("active"); });
    slideshow
        .querySelectorAll(".slide:nth-child(".concat(slideNumber, ")"))
        .forEach(function (el) { return el.classList.add("active"); });
};
var closeSlideshowPopup = function () {
    document
        .querySelectorAll(".slideshow-popup, .slideshow-popup .slide")
        .forEach(function (el) { return el.classList.remove("active"); });
    document.querySelector("body").classList.remove("no-scroll");
};
// Open the slideshow popup
var openSlideshowPopupButtons = document.querySelectorAll(".show-slideshow");
openSlideshowPopupButtons.forEach(function (openSlideshowPopupButton) {
    return openSlideshowPopupButton.addEventListener("click", function () {
        var _a;
        var slideshow = openSlideshowPopupButton
            .closest("section")
            .querySelector(".slideshow-popup");
        if (!slideshow)
            return;
        openSlide(slideshow, (_a = parseInt(openSlideshowPopupButton.dataset.slide, 10)) !== null && _a !== void 0 ? _a : 1);
        slideshow.classList.add("active");
        document.querySelector("body").classList.add("no-scroll");
    });
});
// Navigate to previous slide
var prevSlideButtons = document.querySelectorAll(".slideshow-popup .arrows .left");
prevSlideButtons.forEach(function (prevSlideButton) {
    return prevSlideButton.addEventListener("click", function () {
        var slideshow = prevSlideButton.closest(".slideshow-popup");
        var numberOfSlides = slideshow.querySelectorAll(".slide").length;
        var currentSlideNumber = parseInt(slideshow.querySelector(".slide.active").dataset.slide, 10);
        if (currentSlideNumber === 1) {
            openSlide(slideshow, numberOfSlides);
        }
        else {
            openSlide(slideshow, currentSlideNumber - 1);
        }
    });
});
// Navigate to next slide
var nextSlideButtons = document.querySelectorAll(".slideshow-popup .arrows .right");
nextSlideButtons.forEach(function (nextSlideButton) {
    return nextSlideButton.addEventListener("click", function () {
        var slideshow = nextSlideButton.closest(".slideshow-popup");
        var numberOfSlides = slideshow.querySelectorAll(".slide").length;
        var currentSlideNumber = parseInt(slideshow.querySelector(".slide.active").dataset.slide, 10);
        if (currentSlideNumber === numberOfSlides) {
            openSlide(slideshow, 1);
        }
        else {
            openSlide(slideshow, currentSlideNumber + 1);
        }
    });
});
// Close the slideshow popup
var closeSlideshowPopupButtons = document.querySelectorAll(".slideshow-popup .close");
closeSlideshowPopupButtons.forEach(function (closeSlideshowPopupButton) {
    return closeSlideshowPopupButton.addEventListener("click", function () {
        return closeSlideshowPopup();
    });
});
