var didScroll = false;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = document.querySelector("header").offsetHeight;
var hasScrolled = function () {
    var st = window.scrollY;
    if (st)
        if (Math.abs(lastScrollTop - st) <= delta)
            return;
    if (st > lastScrollTop && st > navbarHeight) {
        document.querySelector("header").classList.add("header--up");
    }
    else if (st + window.screen.height <
        document.documentElement.scrollHeight) {
        document.querySelector("header").classList.remove("header--up");
    }
    lastScrollTop = st;
};
window.addEventListener("scroll", function () {
    didScroll = true;
});
setInterval(function () {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);
