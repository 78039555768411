// Select the button and the article container
var newsButton = document.querySelector(".news .news__button");
var article = document.querySelectorAll(".news .news__grid .article--hidden");
// Check if both elements exist
if (newsButton && article.length > 0) {
    newsButton.addEventListener("click", function () {
        // Loop through each hidden article and remove the hidden class
        article.forEach(function (card) {
            card.classList.remove("article--hidden");
        });
        // Remove the button
        newsButton.remove();
    });
}
