"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var slidetoggle_1 = require("slidetoggle");
// Menu toggle
var menuToggles = document.querySelectorAll(".menu-toggle");
menuToggles.forEach(function (menuToggle) {
    return menuToggle.addEventListener("click", function () {
        menuToggle.classList.toggle("active");
        document.querySelector("body").classList.toggle("no-scroll");
        (0, slidetoggle_1.toggle)(document.querySelector(".main-navigation ul.menu"), { miliseconds: 400, transitionFunction: "swing" });
    });
});
// Submenu toggle
var subMenuToggles = document.querySelectorAll(".submenu-toggle");
subMenuToggles.forEach(function (subMenuToggle) {
    return subMenuToggle.addEventListener("click", function () {
        // Only reset the active state if the clicked submenu isn't active yet
        if (!subMenuToggle.classList.contains("active")) {
            document
                .querySelectorAll(".sub-menu[data-slide-toggle='shown']")
                .forEach(function (el) {
                (0, slidetoggle_1.hide)(el, {
                    miliseconds: 400,
                    transitionFunction: "swing",
                });
            });
            document
                .querySelectorAll(".submenu-toggle.active")
                .forEach(function (el) { return el.classList.remove(".active"); });
        }
        // Always toggle the active state of the clicked submenu
        subMenuToggle.classList.toggle("active");
        (0, slidetoggle_1.toggle)(subMenuToggle.nextElementSibling, {
            miliseconds: 400,
            transitionFunction: "swing",
        });
    });
});
