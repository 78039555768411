"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var slidetoggle_1 = require("slidetoggle");
var accordions = document.querySelectorAll(".accordion__question");
accordions.forEach(function (accordion) {
    return accordion.addEventListener("click", function () {
        // Only reset the active state if the clicked accordion isn't active yet
        if (!accordion
            .closest(".accordion")
            .classList.contains("accordion--active")) {
            document
                .querySelectorAll(".accordion--active .accordion__answer")
                .forEach(function (el) {
                (0, slidetoggle_1.hide)(el, {
                    miliseconds: 400,
                    transitionFunction: "swing",
                });
            });
            document
                .querySelectorAll(".accordion--active")
                .forEach(function (el) { return el.classList.remove("accordion--active"); });
        }
        // Always toggle the active state of the clicked accordion
        accordion.closest(".accordion").classList.toggle("accordion--active");
        (0, slidetoggle_1.toggle)(accordion.nextElementSibling, {
            miliseconds: 400,
            transitionFunction: "swing",
        });
    });
});
