var notificationStatus = sessionStorage.getItem("notificationStatus");
if (notificationStatus !== "hidden") {
    document
        .querySelectorAll(".notification")
        .forEach(function (el) { return el.classList.add("notification--active"); });
}
var closeNotifications = document.querySelectorAll(".notification__close");
closeNotifications.forEach(function (closeNotification) {
    return closeNotification.addEventListener("click", function () {
        document
            .querySelectorAll(".notification")
            .forEach(function (el) { return el.classList.remove("notification--active"); });
        sessionStorage.setItem("notificationStatus", "hidden");
    });
});
