"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var swiper_1 = require("swiper");
var modules_1 = require("swiper/modules");
require("swiper/scss");
require("swiper/scss/autoplay");
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var uspsSwiper = new swiper_1.default(".usps .swiper", {
    modules: [modules_1.Autoplay],
    slidesPerView: 1.4,
    loop: true,
    speed: 15000,
    autoplay: {
        delay: 0,
    },
    breakpoints: {
        700: {
            slidesPerView: 3,
        },
    },
});
