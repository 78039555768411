"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var swiper_1 = require("swiper");
var modules_1 = require("swiper/modules");
require("swiper/scss");
require("swiper/scss/scrollbar");
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var managementSwiper = new swiper_1.default(".management .swiper", {
    slidesPerView: 'auto',
    spaceBetween: 10,
    loop: false,
    modules: [modules_1.Scrollbar],
    scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
    },
});
