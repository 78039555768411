/**
 * @param popupName	The data-popup attribute associated with the popup
 */
var openPopup = function (popupName) {
    if (!popupName)
        return;
    document
        .querySelectorAll(".popup[data-popup*='".concat(popupName, "']"))
        .forEach(function (el) { return el.classList.add("popup--active"); });
    document
        .querySelectorAll(".popup[data-popup*='".concat(popupName, "'] h3[data-title='").concat(popupName, "']"))
        .forEach(function (el) { return el.classList.add("active"); });
    document
        .querySelectorAll(".popup-background")
        .forEach(function (el) { return el.classList.add("popup-background--active"); });
    document.querySelector("body").classList.add("no-scroll");
    setTimeout(function () {
        document
            .querySelector(".popup-background")
            .classList.add("popup-background--show");
    }, 10);
    setTimeout(function () {
        document
            .querySelectorAll(".popup[data-popup*='".concat(popupName, "']"))
            .forEach(function (el) { return el.classList.add("popup--show"); });
    }, 100);
};
var closePopup = function () {
    // Resets iframes
    document
        .querySelectorAll(".popup.active iframe")
        .forEach(function (el) { return el.setAttribute("src", el.getAttribute("src")); });
    // Then close the popups
    document
        .querySelectorAll(".popup")
        .forEach(function (el) { return el.classList.remove("popup--active", "popup--show"); });
    document
        .querySelectorAll(".popup-background")
        .forEach(function (el) {
        return el.classList.remove("popup-background--active", "popup-background--show");
    });
    document.querySelector("body").classList.remove("no-scroll");
};
var showPopupButtons = document.querySelectorAll(".show-popup");
showPopupButtons.forEach(function (showPopupButton) {
    return showPopupButton.addEventListener("click", function () { var _a; return openPopup((_a = showPopupButton.dataset.popup) !== null && _a !== void 0 ? _a : ""); });
});
var closePopupButtons = document.querySelectorAll(".popup__close, .popup-background");
closePopupButtons.forEach(function (closePopupButton) {
    return closePopupButton.addEventListener("click", function () { return closePopup(); });
});
